.subtitle {
	color: var(--color-blue-900);

	font-weight: 800;
	font-size: 44px;
	line-height: 53px;

	text-transform: capitalize;
}

.bubble-column-right {
	flex: 1;
}

.star {
	position: absolute;
	animation-timing-function: cubic-bezier(0, 1.9, 0.95, -0.93);

	animation: float-randomly -3s ease-in-out 5s infinite normal forwards;
}

.poping-image-container {
	position: absolute;
	opacity: 0;
}

.poping-image {
	position: relative;
}

.top-icon {
	position: absolute;
	top: -30px;
	left: -30px;
}

.image-appear {
	animation-name: image-appear;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-direction: normal;
}

@keyframes float-randomly {
	0% {
		transform: rotate(0deg) translate(0px, 10px);
	}

	50% {
		transform: rotate(200deg) translate(100px, 140px);
	}

	100% {
		transform: rotate(360deg) translate(0px, 10px);
	}
}

@keyframes image-appear {
	0% {
		transform: translateY(400px);
		opacity: 0;
	}

	20% {
		opacity: 0.8;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

.partners-title {
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: center;
	align-items: center;
}

.trusted-container {
	display: flex;
	flex-direction: row;

	align-items: flex-end;
}

.trusted-dot {
	margin: 18px 4px;
}
